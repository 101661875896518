import registerServer from '@/utils/registerServer';
import request from '@/utils/request';

let api_host = `/v1`;
const api = {
  loginCaip: `${api_host}/caip/auto_login`,
  logoutCaip: `${api_host}/user/auto_logout`,
};
const { loginCaip, logoutCaip } = api;

const methods = {
  loginCaip: {
    url: loginCaip,
    method: 'post',
  },
  logoutCaip: {
    url: logoutCaip,
    method: 'post',
  },
} as const;

const caipService = registerServer<keyof typeof methods>(methods, request);

export default caipService;
