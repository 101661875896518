// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-chunk/model';
import model_2 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-file/model';
import model_3 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-setting/model';
import model_4 from '/ragflow/web/src/pages/add-knowledge/components/knowledge-testing/model';
import model_5 from '/ragflow/web/src/pages/add-knowledge/model';
import model_6 from '/ragflow/web/src/pages/caip-login/model';
import model_7 from '/ragflow/web/src/pages/chat/model';
import model_8 from '/ragflow/web/src/pages/file-manager/model';
import model_9 from '/ragflow/web/src/pages/knowledge/model';
import model_10 from '/ragflow/web/src/pages/login/model';
import model_11 from '/ragflow/web/src/pages/user-setting/model';

export const models = {
model_1: { namespace: 'add-knowledge.components.knowledge-chunk.model', model: model_1 },
model_2: { namespace: 'add-knowledge.components.knowledge-file.model', model: model_2 },
model_3: { namespace: 'add-knowledge.components.knowledge-setting.model', model: model_3 },
model_4: { namespace: 'add-knowledge.components.knowledge-testing.model', model: model_4 },
model_5: { namespace: 'add-knowledge.model', model: model_5 },
model_6: { namespace: 'caip-login.model', model: model_6 },
model_7: { namespace: 'chat.model', model: model_7 },
model_8: { namespace: 'file-manager.model', model: model_8 },
model_9: { namespace: 'knowledge.model', model: model_9 },
model_10: { namespace: 'login.model', model: model_10 },
model_11: { namespace: 'user-setting.model', model: model_11 },
} as const
